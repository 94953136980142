import React from 'react';
import { Link, Box } from '@chakra-ui/core';
import styled from 'styled-components';
import { IoMdSad } from 'react-icons/io';
import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';

const NotFoundContainer = styled.div`
  width: 100%;
  height: 68vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const NotFound = styled.h1`
  font-weight: 500;
  font-size: 24px;
  margin: 16px;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundContainer>
      <Box as={IoMdSad} size="120px" />
      <TextContainer>
        <NotFound>404 PAGE NOT FOUND</NotFound>
        <p>Désolé mais cette page n'existe pas.</p>
        <Link href="/" color="blue.700">
          Retourner a l'acceuil{' '}
        </Link>
      </TextContainer>
    </NotFoundContainer>
  </Layout>
);

export default NotFoundPage;
